import * as Yup from "yup";

// These validators are common across content type imports (PDF, Article, etc)
export const nameValidator = Yup.string()
    .max(50, "Name too long (50 characters max)")
    .test("name-required", "Name is required", function (value) {
        if (!this.parent.newSource) return true;
        return this.parent.newSource && value && !!value.length;
    });

export const V3DateValidator = Yup.string()
    .nullable()
    .test("valid date", "Please input a valid date", function (value) {
        if (typeof value === "undefined") return false;
        if (value === null) return true;

        try {
            const date = new Date(value);
            return !isNaN(date.getTime());
        } catch {
            return false;
        }
    });

export const sourceValidator = Yup.string().when("newSource", {
    is: (newSource) => !newSource,
    then: Yup.string().required("Please select a source")
});

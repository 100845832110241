import {
    CreateUserArticleTemporaryImage,
    DeleteUserArticle,
    FindContentSnippets,
    FindSnippets,
    UpdateUserArticle,
    UserArticle,
    UserArticleShareOff,
    UserArticleShareOn,
    UserArticleShareToken,
    UserCollectionAddArticle,
    UserCollectionRemoveArticle,
    UserExport
} from "authory-api-types/dist/endpoints";
import axios from "axios";
import { UpdateArticlePayload } from "authory-api-types/dist/types/content";
import { ContentResponse, ContentWithSharesResponse, FindSnippetsResponse, SnippetRecommenderResponse } from "authory-api-types/dist/types/response";
import { CommonArgs } from "./collections";
import { getHeaders } from "./get-headers";
import { API_URL } from "./index";
import { FindUrlSnippetsBodyParams, ModifyCollectionContent, SnippetRecommenderQueryParams } from "authory-api-types/dist/types";

export const getUserExport = async (token: string, userSlug: string) => {
    const { data } = await axios.get<{
        timestamp: string;
        token: string;
        xmlExportUrl: string;
        xmlExportUrlWithoutDomain: string;
        zipExportUrl: string;
        zipExportUrlWithoutDomain: string;
    }>(`${API_URL}${UserExport.replace(":userId?", userSlug)}`, getHeaders(token));

    return data;
};
interface getItemArgs {
    itemSlug: string;
    token: string | null;
    authorSlug: string;
}

export const getArticle = async ({ token, authorSlug, itemSlug }: getItemArgs) => {
    const stringToReplace = !!authorSlug.length ? ":userId?" : "/:userId?";
    const { data } = await axios.get<ContentWithSharesResponse>(
        `${API_URL}${UserArticle.replace(stringToReplace, authorSlug).replace(":articleId", itemSlug)}`,
        token ? getHeaders(token) : undefined
    );

    return data;
};

interface updateShareArgs extends CommonArgs {
    status: boolean;
    itemSlug: string;
}

export const updateArticleShare = async ({ token, userSlug, status, itemSlug }: updateShareArgs) => {
    const url = status
        ? `${API_URL}${UserArticleShareOn.replace(":userId?", userSlug).replace(":articleId", itemSlug)}`
        : `${API_URL}${UserArticleShareOff.replace(":userId?", userSlug).replace(":articleId", itemSlug)}`;

    await axios.get(url, getHeaders(token));

    const { data } = await axios.get<{ token: string }>(
        `${API_URL}${UserArticleShareToken.replace(":userId?", userSlug).replace(":articleId", itemSlug)}`,
        getHeaders(token)
    );

    return data;
};
interface updateArticleArgs extends CommonArgs {
    data: UpdateArticlePayload;
    articleSlug: string;
    target?: string;
}

export const updateArticle = async ({ token, userSlug, data, articleSlug }: updateArticleArgs) => {
    const res = await axios.post<ContentResponse>(
        `${API_URL}${UpdateUserArticle.replace(":userId?", userSlug).replace(":articleId", encodeURIComponent(articleSlug))}`,
        {
            ...data
        },
        getHeaders(token)
    );

    return res.data;
};

interface deleteArticleArgs extends CommonArgs {
    itemSlug: string;
}

export const deleteArticle = async ({ token, userSlug, itemSlug }: deleteArticleArgs) => {
    const res = await axios.post<ContentResponse>(
        `${API_URL}${DeleteUserArticle.replace(":userId?", userSlug).replace(":articleId", encodeURIComponent(itemSlug))}`,
        {},
        getHeaders(token)
    );

    return res.data;
};

interface addOrRemoveArticleFromCollectionArgs extends CommonArgs {
    collectionSlug: string;
    payload: ModifyCollectionContent;
    add: boolean;
    skipContentInvalidation?: boolean;
    useContentDetaisOptimisticUpdate?: boolean;
}

export const addOrRemoveArticleFromCollection = async ({ token, userSlug, collectionSlug, payload, add }: addOrRemoveArticleFromCollectionArgs) => {
    const endpoint = add ? UserCollectionAddArticle : UserCollectionRemoveArticle;

    const res = await axios.post<ContentResponse>(
        `${API_URL}${endpoint.replace(":userId?", userSlug).replace(":collectionId", encodeURIComponent(collectionSlug))}`,
        { ...payload },
        getHeaders(token)
    );

    return res.data;
};

type UploadTemporaryImageArgs = {
    token: string;
    file: File;
    userSlug: string;
    noResize?: boolean;
};

export const uploadTemporaryImage = async ({ token, file, userSlug, noResize = false }: UploadTemporaryImageArgs) => {
    let formData = new FormData();
    formData.append("file", file);

    const params: any = {};
    if (noResize) params.noResize = noResize;

    const { data } = await axios.post<{ url: string }>(`${API_URL}${CreateUserArticleTemporaryImage.replace(":userId?", userSlug)}`, formData, {
        params,
        headers: {
            ...getHeaders(token).headers,
            "Content-Type": "multipart/form-data"
        }
    });

    return data;
};

interface FindSnippetsArgs {
    params: SnippetRecommenderQueryParams;
    token: string;
    timeout?: number;
}

export const findSnippets = async ({ token, params, timeout }: FindSnippetsArgs) => {
    const res = await axios.get<SnippetRecommenderResponse>(`${API_URL}${FindSnippets}`, {
        params,
        timeout,
        ...getHeaders(token || "")
    });

    return res.data;
};

interface FindContentSnippetsArgs {
    params: FindUrlSnippetsBodyParams;
    token: string;
}

export const findContentSnippets = async ({ token, params }: FindContentSnippetsArgs) => {
    const res = await axios.post<FindSnippetsResponse>(`${API_URL}${FindContentSnippets}`, params, {
        ...getHeaders(token || "")
    });

    return res.data;
};

import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const SIPWrapper = styled.div<{$customPadding?: string}>`
    padding: ${({ $customPadding }) => $customPadding ? $customPadding : "12px 24px"};
    align-items: center;
    display: flex;
    border-radius: 6px;
    border: 1px solid ${theme.v3.colors.midYellow};
    background: ${theme.v3.colors.lightYellow};
    width: 100%;
    flex-direction: column;
    text-align: center;

    ${media.phone} {
        padding: ${({ $customPadding }) => $customPadding ? $customPadding : "12px 32px;"};
        flex-direction: row;
        text-align: left;
    }

    .recharts-wrapper {
        min-width: 48px;
        min-height: 48px;
    }

    > div {
        display: inline-flex;

        &:nth-child(2) {
            width: 100%;
            margin-left: 12px;
            margin-top: 8px;

            ${media.phone} {
                margin-top: 0;
            }
        }
    }
`;

export const SIPTextWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    > div {
        &:first-child {
            > * {
                margin-bottom: 6px;

                ${media.phone} {
                    margin-bottom: 0;
                }
            }
        }
    }
`;